/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';
import { createPinia } from 'pinia';
import Devise from 'devisephp-interface';
import { sync } from 'vuex-router-sync';
import PortalVue from 'portal-vue';
import VueYoutube from 'vue-youtube';
import SlitherSlider from 'slither-slider';
import VueObserveVisibility from 'vue-observe-visibility';

import { mapGetters } from 'vuex';

// Devise requires a bus, vue-router and vuex. We initialize these in your application
// so that both apps can share the same store and router. All devise vuex
// is namespaced under the "devise" namespace.
import { EventBus } from './event-bus.js';
import router from './router/route.config.js';
import store from './vuex/store';

import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

// Vue Tippy
import VueTippy, { TippyComponent } from 'vue-tippy';

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

// Pinia
const pinia = createPinia();
Vue.use(pinia);

// Scroll Trigger
import ScrollTrigger from './directives/scroll-trigger.js';

// Non-async components
import GeneralSlider from './components/GeneralSlider.vue';
import GeneralSwiper from './components/GeneralSwiper.vue';
import Hero from './components/Hero.vue';

require('./bootstrap');
require('es6-promise').polyfill();

window.bus = EventBus;
sync(store, router);

// We initialize the Devise plugin and pass our router, store, and bus to share
// // these resources so that your application can tap into them.
Vue.use(Devise, {
    store,
    router,
    bus: window.bus,
    options: {
        adminClass: '',
    },
});

Vue.use(PortalVue);
Vue.use(VueYoutube);
Vue.use(SlitherSlider);
Vue.use(VueObserveVisibility);

Vue.directive('scroll-trigger', ScrollTrigger);
Vue.component('general-slider', GeneralSlider);
Vue.component('general-swiper', GeneralSwiper);

import { Swiper, SwiperSlide } from 'swiper-vue2';

Vue.component('swiper', Swiper);
Vue.component('swiper-slide', SwiperSlide);

Vue.component('wrap-node', {
    props: {
        value: { type: [Object, Array], required: true },
        tag: { type: String, default: 'div' },
        options: {
            type: Object,
            default: () => {},
        },
    },
    render: function (h) {
        let nodes = this.value;
        if (!Array.isArray(nodes)) {
            nodes = [nodes];
        }
        return h(this.tag, this.options, nodes);
    },
});

Vue.component('hero', Hero);

// Async components
// Transitions
Vue.component('fade-transition', () =>
    import(/* webpackChunkName: "fade" */ './components/transitions/Fade.vue')
);

Vue.component('slice', () =>
    import('devisephp-interface/src/components/slices/Slice.vue')
);

// Admin
Vue.component('devise-booking-code-trigger-create', () =>
    import(
        /* webpackChunkName: "app-admin-booking-code-triggers" */ './components/devise-admin/booking-code-triggers/steps/Create.vue'
    )
);
Vue.component('devise-booking-code-trigger-edit', () =>
    import(
        /* webpackChunkName: "app-admin-booking-code-triggers" */ './components/devise-admin/booking-code-triggers/steps/Edit.vue'
    )
);
Vue.component('devise-booking-code-trigger-delete', () =>
    import(
        /* webpackChunkName: "app-admin-booking-code-triggers" */ './components/devise-admin/booking-code-triggers/steps/Delete.vue'
    )
);

Vue.component('devise-fact-sheet-create', () =>
    import(
        /* webpackChunkName: "app-admin-fact-sheets" */ './components/devise-admin/fact-sheets/steps/Create.vue'
    )
);
Vue.component('devise-fact-sheet-edit', () =>
    import(
        /* webpackChunkName: "app-admin-fact-sheets" */ './components/devise-admin/fact-sheets/steps/Edit.vue'
    )
);
Vue.component('devise-fact-sheet-delete', () =>
    import(
        /* webpackChunkName: "app-admin-fact-sheets" */ './components/devise-admin/fact-sheets/steps/Delete.vue'
    )
);

Vue.component('devise-brand-create', () =>
    import(
        /* webpackChunkName: "app-admin-brands" */ './components/devise-admin/brands/steps/Create.vue'
    )
);
Vue.component('devise-brand-edit', () =>
    import(
        /* webpackChunkName: "app-admin-brands" */ './components/devise-admin/brands/steps/Edit.vue'
    )
);

Vue.component('devise-clock-create', () =>
    import(
        /* webpackChunkName: "app-admin-clocks" */ './components/devise-admin/clocks/steps/Create.vue'
    )
);
Vue.component('devise-clock-edit', () =>
    import(
        /* webpackChunkName: "app-admin-clocks" */ './components/devise-admin/clocks/steps/Edit.vue'
    )
);
Vue.component('devise-clock-delete', () =>
    import(
        /* webpackChunkName: "app-admin-clocks" */ './components/devise-admin/clocks/steps/Delete.vue'
    )
);

Vue.component('devise-event-create', () =>
    import(
        /* webpackChunkName: "app-admin-events" */ './components/devise-admin/events/steps/Create.vue'
    )
);
Vue.component('devise-event-edit', () =>
    import(
        /* webpackChunkName: "app-admin-events" */ './components/devise-admin/events/steps/Edit.vue'
    )
);
Vue.component('devise-event-delete', () =>
    import(
        /* webpackChunkName: "app-admin-events" */ './components/devise-admin/events/steps/Delete.vue'
    )
);

Vue.component('devise-restaurant-create', () =>
    import(
        /* webpackChunkName: "app-admin-restaurants" */ './components/devise-admin/restaurants/steps/Create.vue'
    )
);

Vue.component('devise-restaurant-archived', () =>
    import(
        /* webpackChunkName: "app-admin-restaurants" */ './components/devise-admin/restaurants/steps/Archived.vue'
    )
);
Vue.component('devise-restaurant-edit', () =>
    import(
        /* webpackChunkName: "app-admin-restaurants" */ './components/devise-admin/restaurants/steps/Edit.vue'
    )
);
Vue.component('devise-restaurant-delete', () =>
    import(
        /* webpackChunkName: "app-admin-restaurants" */ './components/devise-admin/restaurants/steps/Delete.vue'
    )
);

Vue.component('devise-content-section-create', () =>
    import(
        /* webpackChunkName: "app-admin-content-sections" */ './components/devise-admin/content-sections/steps/Create.vue'
    )
);
Vue.component('devise-content-section-edit', () =>
    import(
        /* webpackChunkName: "app-admin-content-sections" */ './components/devise-admin/content-sections/steps/Edit.vue'
    )
);
Vue.component('devise-content-section-delete', () =>
    import(
        /* webpackChunkName: "app-admin-content-sections" */ './components/devise-admin/content-sections/steps/Delete.vue'
    )
);

Vue.component('devise-promotion-create', () =>
    import(
        /* webpackChunkName: "app-admin-promotions" */ './components/devise-admin/promotions/steps/Create.vue'
    )
);
Vue.component('devise-promotion-edit', () =>
    import(
        /* webpackChunkName: "app-admin-promotions" */ './components/devise-admin/promotions/steps/Edit.vue'
    )
);
Vue.component('devise-promotion-delete', () =>
    import(
        /* webpackChunkName: "app-admin-promotions" */ './components/devise-admin/promotions/steps/Delete.vue'
    )
);

Vue.component('devise-menu-create', () =>
    import(
        /* webpackChunkName: "app-admin-menus" */ './components/devise-admin/menus/steps/Create.vue'
    )
);
Vue.component('devise-menu-edit', () =>
    import(
        /* webpackChunkName: "app-admin-menus" */ './components/devise-admin/menus/steps/Edit.vue'
    )
);
Vue.component('devise-menu-delete', () =>
    import(
        /* webpackChunkName: "app-admin-menus" */ './components/devise-admin/menus/steps/Delete.vue'
    )
);

Vue.component('devise-resort-create', () =>
    import(
        /* webpackChunkName: "app-admin-resorts" */ './components/devise-admin/resorts/steps/Create.vue'
    )
);
Vue.component('devise-resort-edit', () =>
    import(
        /* webpackChunkName: "app-admin-resorts" */ './components/devise-admin/resorts/steps/Edit.vue'
    )
);
Vue.component('devise-resort-edit-triggers', () =>
    import(
        /* webpackChunkName: "app-admin-resorts" */ './components/devise-admin/resorts/steps/EditTriggers.vue'
    )
);

Vue.component('devise-special-create', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/Create.vue'
    )
);
Vue.component('devise-special-create-images', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/CreateImages.vue'
    )
);
Vue.component('devise-special-create-final-details', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/CreateFinalDetails.vue'
    )
);
Vue.component('devise-special-edit-versions', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/EditVersions.vue'
    )
);
Vue.component('devise-special-edit-content', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/EditContent.vue'
    )
);
Vue.component('devise-special-edit-images', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/EditImages.vue'
    )
);
Vue.component('devise-special-edit-configuration', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/EditConfiguration.vue'
    )
);
Vue.component('devise-special-delete', () =>
    import(
        /* webpackChunkName: "app-admin-specials" */ './components/devise-admin/specials/steps/Delete.vue'
    )
);

Vue.component('devise-post-create', () =>
    import(
        /* webpackChunkName: "app-admin-posts" */ './components/devise-admin/posts/steps/Create.vue'
    )
);
Vue.component('devise-post-edit', () =>
    import(
        /* webpackChunkName: "app-admin-posts" */ './components/devise-admin/posts/steps/Edit.vue'
    )
);
Vue.component('devise-post-delete', () =>
    import(
        /* webpackChunkName: "app-admin-posts" */ './components/devise-admin/posts/steps/Delete.vue'
    )
);

Vue.component('devise-playacares-post-create', () =>
    import(
        /* webpackChunkName: "app-admin-playacares-posts" */ './components/devise-admin/playacares-posts/steps/Create.vue'
    )
);
Vue.component('devise-playacares-post-edit', () =>
    import(
        /* webpackChunkName: "app-admin-playacares-posts" */ './components/devise-admin/playacares-posts/steps/Edit.vue'
    )
);
Vue.component('devise-playacares-post-delete', () =>
    import(
        /* webpackChunkName: "app-admin-playacares-posts" */ './components/devise-admin/playacares-posts/steps/Delete.vue'
    )
);

Vue.component('devise-event-post-create', () =>
    import(
        /* webpackChunkName: "app-admin-event-posts" */ './components/devise-admin/event-posts/steps/Create.vue'
    )
);
Vue.component('devise-event-post-edit', () =>
    import(
        /* webpackChunkName: "app-admin-event-posts" */ './components/devise-admin/event-posts/steps/Edit.vue'
    )
);
Vue.component('devise-event-post-delete', () =>
    import(
        /* webpackChunkName: "app-admin-event-posts" */ './components/devise-admin/event-posts/steps/Delete.vue'
    )
);

// General
Vue.component('amenaties', () =>
    import(/* webpackChunkName: "app-amenaties" */ './components/Amenaties.vue')
);
Vue.component('blog-posts', () =>
    import(
        /* webpackChunkName: "app-blog-posts" */ './components/blog/BlogPosts.vue'
    )
);
Vue.component('navigation-booking', () =>
    import(
        /* webpackChunkName: "app-booking" */ './components/NavigationBooking.vue'
    )
);
Vue.component('booking', () =>
    import(/* webpackChunkName: "app-booking" */ './components/Booking.vue')
);
Vue.component('booking-form', () =>
    import(
        /* webpackChunkName: "app-booking-form" */ './components/BookingForm.vue'
    )
);
Vue.component('inline-booking-form', () =>
    import(
        /* webpackChunkName: "app-inline-booking-form" */ './components/InlineBookingForm.vue'
    )
);
Vue.component('ota-booking', () =>
    import(
        /* webpackChunkName: "app-ota-booking" */ './components/ota-booking/OtaBooking.vue'
    )
);

Vue.component('flight-booking-form', () =>
    import(
        /* webpackChunkName: "app-flight-booking-form" */ './components/flight-booking/FlightBookingForm.vue'
    )
);

Vue.component('brands', () =>
    import(
        /* webpackChunkName: "app-brands-brands" */ './components/brands/Brands.vue'
    )
);
Vue.component('resorts', () =>
    import(
        /* webpackChunkName: "app-brands-resorts" */ './components/brands/Resorts.vue'
    )
);
Vue.component('flip-clock', () =>
    import(/* webpackChunkName: "app-clocks" */ './components/FlipClock.vue')
);
Vue.component('grid-gallery', () =>
    import(
        /* webpackChunkName: "app-grid-gallery" */ './components/GridGallery.vue'
    )
);
Vue.component('login-form', () =>
    import(
        /* webpackChunkName: "app-login-form" */ './components/LoginForm.vue'
    )
);
Vue.component('navigation', () =>
    import(/* webpackChunkName: "app-navigation" */ './components/Navigation')
);
Vue.component('playaresorts-footer', () =>
    import(/* webpackChunkName: "app-navigation" */ './components/Footer')
);
Vue.component('our-resorts', () =>
    import(
        /* webpackChunkName: "app-our-resorts" */ './components/OurResorts.vue'
    )
);
Vue.component('post-details', () =>
    import(
        /* webpackChunkName: "app-post-details" */ './components/blog/PostDetails.vue'
    )
);
Vue.component('press-releases', () =>
    import(
        /* webpackChunkName: "app-press-releases-press-releases" */ './components/press-releases/PressReleases.vue'
    )
);
Vue.component('sign-up-form', () =>
    import(
        /* webpackChunkName: "app-sign-up-form" */ './components/SignUpForm.vue'
    )
);
Vue.component('terms', () =>
    import(/* webpackChunkName: "app-terms" */ './components/Terms.vue')
);
Vue.component('safe-stay', () =>
    import(/* webpackChunkName: "app-stay-safe" */ './components/SafeStay.vue')
);
Vue.component('learn-more', () =>
    import(
        /* webpackChunkName: "app-learn-more" */ './components/LearnMore.vue'
    )
);
Vue.component('room-tour', () =>
    import(/* webpackChunkName: "app-room-tour" */ './components/RoomTour.vue')
);
Vue.component('sign-up-pop-up', () =>
    import(
        /* webpackChunkName: "app-sign-up-pop-up" */ './components/SignUpPopUp.vue'
    )
);
Vue.component('anniversary-sweepstakes-form', () =>
    import(
        /* webpackChunkName: "app-sign-up-pop-up" */ './components/AnniversarySweepstakesForm.vue'
    )
);
Vue.component('seadust-sign-up-pop-up', () =>
    import(
        /* webpackChunkName: "app-seadust-sign-up-pop-up" */ './components/seadust/SeadustSignUpPopUp.vue'
    )
);
Vue.component('resort-meeting-facilities', () =>
    import(
        /* webpackChunkName: "app-resort-meeting-facilities" */ './components/resorts/MeetingFacilities.vue'
    )
);
Vue.component('davinci', () =>
    import(/* webpackChunkName: "app-davinci" */ './components/Davinci.vue')
);
Vue.component('brochure-request', () =>
    import(
        /* webpackChunkName: "app-brochure-request" */ './components/BrochureRequest.vue'
    )
);
Vue.component('find-a-travel-agent', () =>
    import(
        /* webpackChunkName: "app-find-a-travel-agent" */ './components/FindATravelAgent.vue'
    )
);

Vue.component('icon-wedding-reception', () =>
    import('./components/icons/WeddingReception.vue')
);

Vue.component('icon-wedding-cocktail', () =>
    import('./components/icons/WeddingCocktail.vue')
);

Vue.component('icon-wedding-ceremony', () =>
    import('./components/icons/WeddingCeremony.vue')
);

Vue.component('icon-left-arrow', () =>
    import('./components/icons/IconLeftArrow.vue')
);

Vue.component('icon-right-arrow', () =>
    import('./components/icons/IconRightArrow.vue')
);
Vue.component('icon-facebook', () =>
    import(
        /* webpackChunkName: "app-icons-icon-facebook" */ './components/icons/IconFacebook.vue'
    )
);
Vue.component('icon-twitter', () =>
    import(
        /* webpackChunkName: "app-icons-icon-twitter" */ './components/icons/IconTwitter.vue'
    )
);
Vue.component('icon-instagram', () =>
    import(
        /* webpackChunkName: "app-icons-icon-instagram" */ './components/icons/IconInstagram.vue'
    )
);
Vue.component('icon-tiktok', () =>
    import(
        /* webpackChunkName: "app-icons-icon-tiktok" */ './components/icons/IconTiktok.vue'
    )
);
Vue.component('icon-youtube', () =>
    import(
        /* webpackChunkName: "app-icons-icon-youtube" */ './components/icons/IconYouTube.vue'
    )
);
Vue.component('icon-pinterest', () =>
    import(
        /* webpackChunkName: "app-icons-icon-pinterest" */ './components/icons/IconPinterest.vue'
    )
);
Vue.component('icon-hamburger-menu', () =>
    import(
        /* webpackChunkName: "app-icons-icon-hamburger-menu" */ './components/icons/IconHamburger.vue'
    )
);
Vue.component('icon-calendar', () =>
    import(
        /* webpackChunkName: "app-icons-icon-calendar" */ './components/icons/IconCalendar.vue'
    )
);
Vue.component('icon-phone', () =>
    import(
        /* webpackChunkName: "app-icons-icon-phone" */ './components/icons/IconPhone.vue'
    )
);
Vue.component('icon-add', () =>
    import(
        /* webpackChunkName: "app-icons-icon-add" */ './components/icons/IconAdd.vue'
    )
);
Vue.component('icon-download', () =>
    import(
        /* webpackChunkName: "app-icons-icon-download" */ './components/icons/IconDownload.vue'
    )
);
Vue.component('icon-check-in', () =>
    import(
        /* webpackChunkName: "app-icons-icon-check-in" */ './components/icons/IconCheckIn.vue'
    )
);
Vue.component('icon-check-out', () =>
    import(
        /* webpackChunkName: "app-icons-icon-check-out" */ './components/icons/IconCheckOut.vue'
    )
);
Vue.component('icon-volume-mute', () =>
    import(
        /* webpackChunkName: "app-icons-icon-volume-mute" */ './components/icons/IconVolumeMute.vue'
    )
);
Vue.component('icon-volume-high', () =>
    import(
        /* webpackChunkName: "app-icons-icon-volume-high" */ './components/icons/IconVolumeHigh.vue'
    )
);

// Utilities
Vue.component('modal', () => import('./components/utilities/Modal.vue'));

// Logos
Vue.component('svg-logo', () =>
    import(
        /* webpackChunkName: "app-brands-svg-logo" */ './components/brands/SvgLogo.vue'
    )
);
Vue.component('logo-hilton', () =>
    import(
        /* webpackChunkName: "app-logo-hilton" */ './components/logos/Hilton.vue'
    )
);
Vue.component('logo-hyatt-zilara', () =>
    import(
        /* webpackChunkName: "app-logo-hyatt-zilara" */ './components/logos/HyattZilara.vue'
    )
);
Vue.component('logo-marriot-bonvoy', () =>
    import(
        /* webpackChunkName: "app-logo-marriot-bonvoy" */ './components/logos/MarriotBonvoy.vue'
    )
);
Vue.component('logo-tapestry-collection', () =>
    import(
        /* webpackChunkName: "app-logo-tapestry-collection" */ './components/logos/TapestryCollection.vue'
    )
);
Vue.component('logo-hyatt-ziva-zilara', () =>
    import(
        /* webpackChunkName: "app-logo-hyatt-ziva-zilara" */ './components/logos/HyattZivaZilara.vue'
    )
);
Vue.component('logo-hyatt-ziva', () =>
    import(
        /* webpackChunkName: "app-logo-hyatt-ziva" */ './components/logos/HyattZiva.vue'
    )
);
Vue.component('logo-jewel', () =>
    import(
        /* webpackChunkName: "app-logo-jewel" */ './components/logos/Jewel.vue'
    )
);
Vue.component('logo-jewel-grande', () =>
    import(
        /* webpackChunkName: "app-logo-jewel-grande" */ './components/logos/JewelGrande.vue'
    )
);
Vue.component('logo-sanctuary', () =>
    import(
        /* webpackChunkName: "app-logo-sanctuary" */ './components/logos/Sanctuary.vue'
    )
);
Vue.component('logo-turquoize', () =>
    import(
        /* webpackChunkName: "app-logo-turquoize" */ './components/logos/Turquoize.vue'
    )
);
Vue.component('logo-wyndham', () =>
    import(
        /* webpackChunkName: "app-logo-turquoize" */ './components/logos/Wyndham.vue'
    )
);
Vue.component('logo-seadust', () =>
    import(
        /* webpackChunkName: "app-logo-turquoize" */ './components/logos/Seadust.vue'
    )
);
Vue.component('logo-agent-cash-plus', () =>
    import(
        /* webpackChunkName: "app-brands-svg-logo" */ './components/logos/AgentCashPlus.vue'
    )
);

// Wyndham
Vue.component('curvy-lines', () =>
    import('./components/wyndham/CurvyLines.vue')
);

// Zilara
Vue.component('bg-swoosh-up-right-blues', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-up-right-blues" */ './components/background-elements/SwooshUpRightBlues.vue'
    )
);
Vue.component('bg-swoosh-up-right-pinks', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-up-right-pinks" */ './components/background-elements/SwooshUpRightPinks.vue'
    )
);
Vue.component('bg-swoosh-green-top-left', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-green-top-left" */ './components/background-elements/SwooshGreenTopLeft.vue'
    )
);
Vue.component('bg-swoosh-teal-top-left', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-teal-top-left" */ './components/background-elements/SwooshTealTopLeft.vue'
    )
);
Vue.component('bg-swoosh-white-left', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-white-left" */ './components/background-elements/SwooshWhiteLeft.vue'
    )
);
Vue.component('bg-swoosh-white-right', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-white-right" */ './components/background-elements/SwooshWhiteRight.vue'
    )
);
Vue.component('bg-scribble-circle', () =>
    import(
        /* webpackChunkName: "app-bg-scribble-circle" */ './components/background-elements/ScribbleCircle.vue'
    )
);
Vue.component('bg-right-blue-to-yellow', () =>
    import(
        /* webpackChunkName: "app-bg-right-blue-to-yellow" */ './components/background-elements/RightBlueToYellow.vue'
    )
);
Vue.component('bg-left-blue-to-yellow', () =>
    import(
        /* webpackChunkName: "app-bg-left-blue-to-yellow" */ './components/background-elements/LeftBlueToYellow.vue'
    )
);
Vue.component('bg-right-blue-to-pink', () =>
    import(
        /* webpackChunkName: "app-bg-right-blue-to-pink" */ './components/background-elements/RightBlueToPink.vue'
    )
);
Vue.component('bg-left-blue-to-pink', () =>
    import(
        /* webpackChunkName: "app-bg-left-blue-to-pink" */ './components/background-elements/LeftBlueToPink.vue'
    )
);
Vue.component('bg-swoosh-half-right', () =>
    import(
        /* webpackChunkName: "app-bg-swoosh-half-right" */ './components/background-elements/SwooshHalfRight.vue'
    )
);
Vue.component('bg-waves', () =>
    import(
        /* webpackChunkName: "app-bg-waves" */ './components/background-elements/Waves.vue'
    )
);
Vue.component('bg-spider-web', () =>
    import(
        /* webpackChunkName: "app-bg-spider-web" */ './components/background-elements/SpiderWeb.vue'
    )
);
Vue.component('bg-spiral-coral', () =>
    import(
        /* webpackChunkName: "app-bg-spiral-coral" */ './components/background-elements/SpiralCoral.vue'
    )
);
Vue.component('bg-spiral-coral-web', () =>
    import(
        /* webpackChunkName: "app-bg-spiral-coral-web" */ './components/background-elements/SpiralCoralWeb.vue'
    )
);
Vue.component('gluten-free', () =>
    import(
        /* webpackChunkName: "app-menu-dr-icons" */ './components/icons/GlutenFree.vue'
    )
);
Vue.component('low-cal', () =>
    import(
        /* webpackChunkName: "app-menu-dr-icons" */ './components/icons/LowCal.vue'
    )
);
Vue.component('low-carb', () =>
    import(
        /* webpackChunkName: "app-menu-dr-icons" */ './components/icons/LowCarb.vue'
    )
);
Vue.component('keto', () =>
    import(
        /* webpackChunkName: "app-menu-dr-icons" */ './components/icons/Keto.vue'
    )
);
Vue.component('vegan', () =>
    import(
        /* webpackChunkName: "app-menu-dr-icons" */ './components/icons/Vegan.vue'
    )
);
Vue.component('vegetarian', () =>
    import(
        /* webpackChunkName: "app-menu-dr-icons" */ './components/icons/Vegetarian.vue'
    )
);

// Collection
Vue.component('centered-text-parallax', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/CenteredTextParallax.vue'
    )
);
Vue.component('collection-features', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/CollectionFeatures.vue'
    )
);
Vue.component('all-inclusive-plus', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/yucatan/AllInclusivePlus.vue'
    )
);
Vue.component('three-image-cards', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/ThreeImageCards.vue'
    )
);
Vue.component('six-image-cards', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/SixImageCards.vue'
    )
);
Vue.component('two-pane-text-image', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/TwoPaneTextImage.vue'
    )
);
Vue.component('testimonial-quote', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/TestimonialQuote.vue'
    )
);
Vue.component('collection-all-included', () =>
    import(
        /* webpackChunkName: "app-collection" */ './components/collection/CollectionAllIncluded.vue'
    )
);
Vue.component('inline-html5-video', () =>
    import('./components/videos/InlineHtml5Video.vue')
);
Vue.component('property-hub', () =>
    import('./components/property-hub/PropertyHub.vue')
);

Vue.component('curvy-lines', () => import('./components/wyndham/CurvyLines'));
Vue.component('wyndham-icon', () => import('./components/wyndham/Icons'));

// 2022 Alfredo Zilara Redesign

Vue.component('signature-title', () =>
    import('./components/zilara/SignatureTitle.vue')
);
Vue.component('hyatt-special-offer', () =>
    import('./components/zilara/SpecialOffer.vue')
);
Vue.component('hyatt-its-all-included', () =>
    import('./components/zilara/ItsAllIncluded.vue')
);
Vue.component('hyatt-slanted-gallery', () =>
    import('./components/zilara/SlantedGallery.vue')
);
Vue.component('hyatt-two-image-description-overlay', () =>
    import('./components/zilara/TwoImageDescriptionOverlay.vue')
);
Vue.component('hyatt-small-image-small-copy', () =>
    import('./components/zilara/SmallImageSmallCopy.vue')
);
Vue.component('hyatt-fractured-gallery', () =>
    import('./components/zilara/FracturedGallery.vue')
);
Vue.component('hyatt-navy-block-overlay', () =>
    import('./components/zilara/NavyBlockOverlay.vue')
);
Vue.component('hyatt-magazine-overlay', () =>
    import('./components/zilara/MagazineOverlay.vue')
);
Vue.component('restaurant-styles-test-slice', () =>
    import('./components/RestaurantStylesTestSlice.vue')
);

Vue.component('devise-users-playa-create', () =>
    import('./components/devise-admin/users/steps/Create.vue')
);
Vue.component('devise-users-playa-edit', () =>
    import('./components/devise-admin/users/steps/Edit.vue')
);
Vue.component('devise-users-playa-delete', () =>
    import('./components/devise-admin/users/steps/Delete.vue')
);

Vue.component('infinity-mobile-menu', () =>
    import('./components/infinity/InfinityMobileMenu.vue')
);
Vue.component('infinity-luxury-magazine', () =>
    import('./components/infinity/InfinityMagazine.vue')
);

// Agent Cash Plus

Vue.component('agent-cash-plus-navigation', () =>
    import(
        /* webpackChunkName: "app-agent-cash-plus" */ './components/agent-cash-plus/NavigationSystem.vue'
    )
);
Vue.component('agent-cash-plus-dashboard', () =>
    import(
        /* webpackChunkName: "app-agent-cash-plus" */ './components/agent-cash-plus/AgentCashPlusDashboard.vue'
    )
);
Vue.component('agent-cash-filter-specials', () =>
    import(
        /* webpackChunkName: "app-agent-cash-plus" */ './components/agent-cash-plus/AgentCashFilterSpecials.vue'
    )
);

// Popovers
Vue.component('image-card-popover', () =>
    import(
        /* webpackChunkName: "app-popovers" */ './components/popovers/ImageCardPopover.vue'
    )
);

const appMixin = Vue.mixin({
    methods: {
        userCan(permission) {
            if (this.isLoggedIn) {
                const found = this.currentUser.permissions_list.find(
                    (perm) => perm === permission
                );

                if (found) return true;
            }
            return false;
        },
    },
    computed: {
        ...mapGetters(['currentResortAndBrand']),
        randomId() {
            const length = 8;
            let text = '';
            const possible =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (let i = 0; i < length; i++)
                text += possible.charAt(
                    Math.floor(Math.random() * possible.length)
                );

            return text;
        },
    },
});

new Vue({
    el: '#app',
    router,
    data: {
        showSeadustBooking: false,
    },
    computed: {
        ...mapGetters(['appState', 'promoCodes']),
        mobileMenuOpen() {
            return this.appState.mobileMenuOpen;
        },
        codigo: function () {
            // seadust codes sent to redirect to parse and put into booking engine url
            return JSON.stringify({
                group: this.$store.state.promoCodes.group,
                promo: this.$store.state.promoCodes.promo,
                rate: this.$store.state.promoCodes.rate,
            });
        },
    },
    mounted() {
        this.appLoaded();
        this.getPromosUsingCodeTriggers();

        try {
            if (this.currentPage.site.id === 36) {
                // need to figure out how to load this dynamically
                dataLayer.push({
                    hotelName: 'Seadust Cancun Family Resort',
                });
            } else {
                dataLayer.push({
                    hotelName: this.currentPage.site.data.currentResort.name,
                });
            }
        } catch (e) {
            console.log(e);
        }
    },
    methods: {
        appLoaded() {
            window.deviseSettings.$bus.$on('devise-loaded', () => {
                this.$nextTick(() => {
                    this.addListeners();
                });
            });
        },
        addListeners() {
            setTimeout(() => {
                this.showOurResorts();
            }, 1500);


            window.deviseSettings.$bus.$on('toogle-seadust-widget', () => {
                this.showSeadustBooking = !this.showSeadustBooking;
            });
        },
        showOurResorts() {
            const el = document.getElementsByClassName('show-our-resorts');
            for (let i = 0; i < el.length; i++) {
                el[i].addEventListener('click', () => {
                    window.bus.$emit('show-our-resorts');
                });
            }
        },
        getPromosUsingCodeTriggers() {
            ``;
            if (this.currentPage && this.currentPage.site) {
                window.axios
                    .get(
                        '/api/devise/booking-codes/by-resort' +
                            window.location.search
                    )
                    .then((response) => {
                        this.$store.commit('resortPromoCodes', response.data);
                    });
            }
        },
    },
    mixin: [appMixin],
});
